import React, {useEffect, useState} from 'react';
import Image from 'next/image';
import formatCurrency from '@helpers/formatCurrency';
import {Container, Row, Col} from 'reactstrap';
import {Skeleton} from 'antd';

const area =
  'https://locare.s3.sa-east-1.amazonaws.com/sites/common/ruler.png?size=small';
const suites =
  'https://locare.s3.sa-east-1.amazonaws.com/sites/common/banheira.png?size=small';
const quarto =
  'https://locare.s3.sa-east-1.amazonaws.com/sites/common/cama-de-casal.png?size=small';
const garage =
  'https://locare.s3.sa-east-1.amazonaws.com/sites/common/garagem.png?size=small';

type CardImmobileProps = {
  immobile: any;
};

const CardImmobile = ({immobile}: CardImmobileProps): JSX.Element => {
  const [imgDestaque, setImgDestaque] = useState({
    arquivo: null,
  });

  useEffect(() => {
    if (immobile?.imagens?.length > 0) {
      const listDestaque = immobile.imagens.filter((i) => i.destaque);

      if (listDestaque[0]) setImgDestaque(listDestaque[0]);
      else setImgDestaque(immobile.imagens[0]);
    }
  }, [immobile]);

  const immobileCode = immobile?.codImovel;
  const district = immobile?.logradouro?.bairro?.nome
    ? `${immobile?.logradouro?.bairro?.nome}`
    : '';
  const address = `${immobile?.logradouro?.nome} ${district}`;
  const typeRural = [
    'c11f31bd-51fc-4dfb-b4d0-5c5d504bc45a',
    'ed5d6619-599d-417b-af78-56644bdc2a69',
    '03e8d3c4-b98d-402e-bb78-3fc68fc372de',
    '303ab71f-3246-49c5-ba3d-1076cfafed90',
    'f643a3df-1e93-47b8-a8f1-48a6839ac1ef',
    'd8778dd6-6df0-4a1a-882c-93f9a5ecc1ed',
    'd8778dd6-6df0-4a1a-882c-93f9a5ecc1ed',
  ];
  const hasRural = typeRural.includes(immobile?.tipoImovel?.id);
  const cidade = immobile?.logradouro?.bairro?.cidade?.nome;

  const qtdArea = immobile?.caracteristica?.areaConstruida || 0;
  const qtdQuartos = immobile?.caracteristica?.quantidadeQuartos || 0;
  const qtdSuites = immobile?.caracteristica?.quantidadeSuites || 0;
  const qtdGaragens =
    immobile?.caracteristica?.vagasGaragem ||
    0 + immobile?.caracteristica?.vagasGaragemDescobertas ||
    0;

  return (
    <a href={`/imovel/${immobileCode}`} target="_blank">
      <div
        className="single-listing divClick"
        style={{
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <span className="listing-span">
          {immobile?.operacaoImobiliaria === 1
            ? 'Aluguel'
            : immobile?.operacaoImobiliaria === 2
            ? 'Venda'
            : 'Aluguel/Venda'}
        </span>
        <div>
          <div className="list-img">
            {imgDestaque?.arquivo ? (
              <img
                alt="Imagem do imóvel"
                className="card-img"
                src={imgDestaque.arquivo}
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </div>
        <div className="list-caption d-flex flex-column justify-content-between">
          <div className="mb-2">
            <span>
              {cidade}
              {immobile?.tipoImovel?.finalidade?.nome
                ? `  >  ` + immobile?.tipoImovel?.finalidade?.nome
                : null}
              {immobile?.tipoImovel?.descricao
                ? `  >  ` + immobile?.tipoImovel?.descricao
                : null}
            </span>
          </div>
          <div>
            <span className="title-immobile">{immobile?.tituloAmigavel}</span>
          </div>
          <div>
            <ul  style={{paddingLeft: 0}} className="mb-0">
              <li>
                {immobile?.operacaoImobiliaria === 1 ? (
                  <>
                    <span className="listing-price">
                      {formatCurrency(immobile?.valorAluguel)}
                    </span>
                  </>
                ) : immobile?.operacaoImobiliaria === 2 ? (
                  <>
                    <span className="listing-price">
                      {formatCurrency(immobile?.valorVenda)}
                    </span>
                  </>
                ) : (
                  <>
                    <div>
                      <span className="listing-price">
                        {formatCurrency(immobile?.valorAluguel)}
                      </span>{' '}
                    </div>
                    <div>
                      <span className="listing-price">
                        {formatCurrency(immobile?.valorVenda)}
                      </span>{' '}
                    </div>
                  </>
                )}
              </li>
            </ul>
          </div>
          <div className="d-flex mb-4">
            <div style={{minHeight: '35px'}}>
              <span className="address-immobile">{district}</span>
              <p className="address-immobile">
                {immobile?.logradouro?.bairro?.cidade?.nome} -{' '}
                {immobile?.logradouro?.bairro?.cidade?.estadoId}
              </p>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'fit-content',
            }}>
            <div className="d-flex justify-content-center align-items-center">
              <Container>
                <Row
                  className="justify-content-start"
                  style={{
                    flexWrap: 'wrap',
                    height: 'fit-content',
                    gap: 10,
                  }}>
                  {qtdArea > 0 && (
                    <Col>
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Image src={area} width={28} height={28} />
                        <span
                          style={{
                            fontWeight: 600,
                            fontSize: '10px',
                            whiteSpace: 'nowrap',
                          }}>
                          {qtdArea}{' '}
                          <small>{hasRural ? 'Hectares' : 'm²'}</small>
                        </span>
                      </div>
                    </Col>
                  )}
                  {qtdQuartos > 0 && (
                    <Col>
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Image src={quarto} width={28} height={28} />
                        <span
                          style={{
                            fontWeight: 600,
                            fontSize: '10px',
                            whiteSpace: 'nowrap',
                          }}>
                          {qtdQuartos}{' '}
                          <small>Quarto{qtdQuartos > 1 && 's'}</small>
                        </span>
                      </div>
                    </Col>
                  )}
                  {qtdSuites > 0 && (
                    <Col>
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Image src={suites} width={28} height={28} />
                        <span
                          style={{
                            fontWeight: 600,
                            fontSize: '10px',
                            whiteSpace: 'nowrap',
                          }}>
                          {qtdSuites} <small>Suite{qtdSuites > 1 && 's'}</small>
                        </span>
                      </div>
                    </Col>
                  )}
                  {qtdGaragens > 0 && (
                    <Col>
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <Image src={garage} width={28} height={28} />
                        <span
                          style={{
                            fontWeight: 600,
                            fontSize: '10px',
                            whiteSpace: 'nowrap',
                          }}>
                          {qtdGaragens}{' '}
                          <small>Garage{qtdGaragens > 1 ? 'ns' : 'm'}</small>
                        </span>
                      </div>
                    </Col>
                  )}
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default CardImmobile;
